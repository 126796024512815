@import "../../style/variables";
@import "../../style/mixins";

.social-links {
  a {
    margin: 0 em(20)
  }
}

.about-khitmah-screen {
  .inner-bg-box {
    padding: 0 em(40);
    background: url("../../Assets/Images/about-bg.png") no-repeat center top;
    background-size: 100%;
  }

  .head-box {
    text-align: center;
    padding: em(50) 0;
  }

  .content-box {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.13);
    border-radius: em(15);
    background: $white-color;
    padding: em(40) em(40);

    p {
      line-height: em(22);
    }

    .join-btn {
      width: em(300);
      margin: em(30) auto 0 auto;
      display: block;
    }
  }

  .copyright-info-box {
    text-align: center;
    font-size: em(14);
    color: $secondary-color;
    margin-top: em(10);

    .symbol {
      font-size: em(30);
      position: relative;
      top: em(5);
    }
  }

  .termsAndConditionBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;

    .link {
      padding: 0 10px;
    }
  }
}
