.m-custom-scroll {

  > div {
    overflow-x: hidden !important;
  }

  .track-vertical {
    background: transparent;
    overflow: hidden;
    width: em(5) !important;
    right: 0;
    top: 0;
    height: 100% !important;
    opacity: 0.8;
  }

  .thumb-vertical {
    background: $primary-color;
    border-radius: em(50);
  }
}