// Variable overrides

// _typography_variables_sizing
$smallest-font-size: 0.6875rem; //equivalent 11px
$small-font-size: 0.75rem; //equivalent 12px
$medium-font-size: 0.8125rem; //equivalent 13px
$base-font-size: 0.875rem; //equivalent 14px
// $field-font-size: $ea-small-font-size; //equivalent 12px


$h1-font-size: em(45);
$h2-font-size: em(30); 
$h3-font-size: em(25); 
$h4-font-size: em(18); 
$h5-font-size: em(16); 


$primary-color: #00c198;
$secondary-color: #365251;
$tertiary-color: #f44336;
$white-color: #ffffff;
$black-color: #000000;
$content-color: #a0a6a6;
$light-gray: #f7f7f7;
$gray-shade-2: #868686;
$gray-shade-3: #707070;
$gray-shade-4: #f5f6f9;
$border-color: #eeebeb;
$success-color: #00c198;
$error-color: #e55353;
$dark-gray: #385453;
$orange: #ffb733;


$primary-font-family-regular: 'product_sansregular';
$primary-font-family-bold: 'product_sansbold';
$primary-arabic-font:'KFGQPC Uthman Taha Naskh';
$font-family-sf-pro-display: 'SF Pro Display';


