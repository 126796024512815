@import "../style/variables";
@import "../style/mixins";

.cms-khitmah-screen {
  b, strong {
    color: #000;
    font-weight: 500;
  }
  table {
    background-color: $light-gray;
    border: none;
    th,
    td {
      font-weight: normal;
      padding: em(10) em(15);
    }
  }
  .title-main {
    margin: em(10) 0 em(15) 0;
  }
  .title-sub {
    font-size: em(20);
    margin: em(25) 0 em(15) 0;
  }
  ul {
    margin-top: 10px;
    li {
      margin-bottom: 10px;
      line-height: 1.4rem;
    }
  }
  .list-numeric {
    list-style: decimal;
  }
  .list-alphabetic {
    list-style: upper-alpha;
  }
  background: $white-color;
  padding: em(30) em(30);
  border-radius: em(10) em(10) 0 0;
  margin: em(20) 0 em(30) 0;
  .inner-bg-box {
    padding: 0 em(40);
    background: url("../Assets/Images/about-bg.png") no-repeat center top;
    background-size: 100%;
  }

  .head-box {
    text-align: center;
    padding: em(50) 0;
  }

  .content-box {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.13);
    border-radius: em(15);
    background: $white-color;
    padding: em(40) em(40);

    p {
      line-height: em(22);
    }


    .join-btn {
      width: em(300);
      margin: em(30) auto 0 auto;
      display: block;
    }
  }

  .copyright-info-box
  {
    text-align: center;
    font-size: em(14);
    color:$secondary-color;
    margin-top:em(10);

    .symbol
    {
      font-size: em(30);
      position: relative;
      top:em(5);
    }
  }
}

@media (min-width: 768px) {
  .cms-khitmah-screen {
    table {
      width: 50%;
    }
  }
}

@media (max-width: 767px) {
  .cms-khitmah-screen {
    padding: 0;
    margin: 0;
    .content-box {
      padding: em(25) em(25);
    }
    .inner-bg-box {
      padding: 0 em(20);
      background-size: initial;
    }
  }
}
