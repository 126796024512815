@import "../style/variables";
@import "../style/mixins";

.signup-form-box, .login-form-box, .forgot-form-box, .term-screen-box {
  padding: em(80) em(80) em(80) em(80);
  height: 100vh;
  overflow-y: auto;
  background: url('../Assets/Images/login-bg-pattern.svg') repeat;
  background-size: 37%;


  .top-head {
    position: relative;
    text-align: center;

    .back-btn {
      position: absolute;
      left: em(-40);
      top: em(3);
      cursor: pointer;
    }

    .heading {
      font-size: em(22);
      font-family: $primary-font-family-bold;
      @include transitions(0.5s);
    }

  }

  .inner-box {
    width: 100%;
  }

  .invalid-feedback {

  }

  .form-control {
    margin-bottom: em(30);
  }

  .field-group {
    margin-bottom: em(20);
  }

}

.no-record-found {
  text-align: center;
  font-weight: 700;
}

.tool-tip-icon
{
  font-size: em(13);
  margin-left:em(3);
  cursor: pointer;
  color:$secondary-color;
  position: relative;
  top:2px;
}


@media (min-width: 1200px) {
  .signup-form-box, .login-form-box, .forgot-form-box, .term-screen-box {
    .top-head {
      .back-btn {
        &:hover {
          color: $primary-color;
        }
      }
    }

  }
}

// hide choose another option from braintree dropin
[data-braintree-id="toggle"] {
  display: none;
}