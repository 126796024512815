@import "variables";

body {
  font-family: $primary-font-family-regular;
  font-size: 16px;
  overflow-x: hidden;
  background: $white-color;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  color: $primary-color;
  @include transitions(0.8s);
  text-decoration: none !important;
}

p {
  font-size: em(14);
  color: $content-color;
  line-height: em(20);
  margin-bottom: em(20);

  &.highlight {
    font-size: em(18);
  }
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

.primary-btn {
  font-size: em(17);
  background: $primary-color;
  border: 0;
  color: $white-color;
  padding: em(15) em(30);
  height: em(50);
  border-radius: em(10);
  outline: none !important;
  @include transitions(0.8s);
  cursor: pointer;
  box-shadow: none !important;

  &:active,
  &:focus {
    background: $primary-color !important;
  }
}

.secondary-btn {
  font-size: em(17);
  background: $secondary-color;
  border: 0;
  color: $white-color !important;
  padding: em(15) em(30);
  height: em(50);
  border-radius: em(10);
  outline: none !important;
  @include transitions(0.8s);
  cursor: pointer;
  box-shadow: none !important;

  &:active,
  &:focus {
    background: $secondary-color !important;
  }
}

.alert {
  padding: em(10) em(10);
}

.btn-loading {
  position: relative;

  &:before {
    content: "";
    background: url("../Assets/Images/button-loader.gif") no-repeat center;
    width: em(26);
    height: em(26);
    position: absolute;
    right: em(20);
    top: 0;
    bottom: 0;
    margin: auto 0;
    background-size: 100%;
  }
}

.btn-disabled {
  opacity: 0.6;
  background: $gray-shade-2;
}

.no-pointer {
  cursor: default;
}

@media (min-width: 1200px) {
  .primary-btn {
    &:hover {
      background: $secondary-color;
    }
  }
  .secondary-btn {
    &:hover {
      background: $primary-color;
    }
  }
}

@media (min-width: 980px) and (max-width: 1400px) {
  body {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body {
    font-size: 12px;
  }

  .primary-btn,
  .secondary-btn {
    padding: em(15) em(15);
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 10px;
  }
}
