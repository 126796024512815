@import "../style/variables";
@import "../style/mixins";


.c-main {
  background: $light-gray;
  padding-top: em(25);

  h1 {
    font-family: $primary-font-family-bold;
    color: $primary-color;
    font-size: em(30);
  }

  .generic-inner-sec {
    background: $white-color;
    padding: em(30) em(30);
    border-radius: em(10) em(10) 0 0;
    margin: em(20) 0 em(30) 0;

    .sub-heading {
      color: $primary-color;
      font-size: em(14);
      font-family: $primary-font-family-bold;
      margin-top: em(15);
    }

    .sub-heading-style2 {
      color: $secondary-color;
      font-size: em(13);
      font-family: $primary-font-family-bold;
      margin-top: em(15);
      margin-bottom: em(20);
    }
  }
}

.tb-policy {
    th {
      border: 1px solid $black-color;
      border-collapse: collapse;
      font-weight: bold !important;
    }

    td {
      border: 1px solid $black-color;
      border-collapse: collapse;
    }
}


