@import "../style/variables";
@import "../style/mixins";

.c-sidebar {
  background: $white-color;

  .c-sidebar-footer {
    background: $white-color;
    padding-left: 0;
    padding-right: 0;
  }


  .logout-btn {
    padding: 0 em(20) 0 em(25);
    position: relative;

    &:before {
      content: "";
      width: em(8);
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: $primary-color;
      border-radius: 0 em(5) em(5) 0;
      @include transitions(0.5s);
      opacity: 0;

       [dir=rtl] & {
        left: auto;
        right: 0;
        border-radius: em(5) 0 0 em(5);
       }


    }

    .link {
      font-size: em(17);
      font-weight: 600;
      color: $secondary-color;
      letter-spacing: 1px;
      position: relative;
      padding: em(13) 0 em(13) em(50);
      border-radius: em(5);
      line-height: em(20);
      @include transitions(0.5s);
      display: block;
      cursor: pointer;

      i {
        position: absolute;
        left: em(10);
        top: em(8);
        font-size: em(22);

        &.icon-payment-info {
          font-size: em(17);
          top: em(14);
        }

        &.icon-donation {
          font-size: em(17);
          top: em(14);
        }
      }

      [dir=rtl] & {
        padding: em(13) em(50) em(13) em(0);
        i {
          left: em(0);
          right: em(10);
        }


      }

    }


  }

}

.dashboard-logo-box {
  border-bottom: 2px solid $light-gray;
  padding: em(8) em(30) em(8) em(35);
  height: em(94);

  .logo {
    width: em(120);

    img {
      max-width: 100%;
    }
  }
}

.c-sidebar-nav {
  padding-top: em(20);

  .c-sidebar-nav-item {
    margin-bottom: em(20);
    padding: 0 em(20) 0 em(25);
    position: relative;

    .c-sidebar-nav-link {

      &:hover {
        &:before {
          opacity: 1;
        }
      }

      &:before {

        [dir=rtl] & {
          left: auto;
          border-radius: em(5) 0  0 em(5) ;
          right: em(-19);
        }

        content: "";
        width: em(8);
        height: 100%;
        position: absolute;
        left: em(-24);
        top: 0;
        background: $primary-color;
        border-radius: 0 em(5) em(5) 0;
        @include transitions(0.5s);
        opacity: 0;
      }

      font-size: em(17);
      font-weight: 600;
      color: $secondary-color;
      letter-spacing: 1px;
      position: relative;
      padding: em(13) 0 em(13) em(50);
      border-radius: em(5);
      line-height: em(20);
      @include transitions(0.5s);
      background: none;
      white-space: normal;

      i {
        position: absolute;
        left: em(10);
        top: em(8);
        font-size: em(22);

        &.icon-donate {
          &:before {
            font-size: em(20);
          }
        }

        &.icon-payment-info {
          font-size: em(17);
          top: em(14);
        }

        &.icon-donation {
          font-size: em(17);
          top: em(14);
        }
      }

      [dir=rtl] & {
        padding: em(13) em(50)  em(13) 0;
        i {
          right: em(10);
          left:  auto;
        }
      }

      &.c-active {
        &:before {
          opacity: 1;
        }
        background: $primary-color;
        color: $white-color;
      }
    }
  }
}


@media (min-width: 1200px) {
  .c-sidebar-nav {
    .c-sidebar-nav-item {

      &:hover {
        &:before {
          opacity: 1;
        }

        .c-sidebar-nav-link {
          background: $primary-color;
          color: $white-color;

          i {
            color: $white-color;
          }
        }
      }
    }
  }

  .c-sidebar {
    .logout-btn {

      &:hover {
        &:before {
          opacity: 1;
        }

        .link {
          background: $primary-color;
          color: $white-color;

          i {
            color: $white-color;
          }
        }
      }
    }
  }
}