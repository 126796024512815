@import "../style/variables";
@import "../style/mixins";


.c-header {
  padding: 0;
  height: em(94);

  .row {
    width: 100%;
  }
}

.toggle-sidenav-btn {
  display: none;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .toggle-sidenav-btn
  {
    display: block;
    padding:0;
    margin:0 em(20) 0 em(20);


    .sidebar-toggler
    {
      padding:em(3) em(5);
    }
  }
}