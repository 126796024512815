.nav-tabs {
  border: 0;
  margin-top: em(8);


  .nav-item {
    margin-right: em(30);
  }

  .nav-link {
    padding: 0;
    border: 0;
    outline: none;
    box-shadow: none;
    color: #bcc1c1 !important;
    font-size: em(18);
    font-weight: 600;
    position: relative;
    background: none !important;

    &:before {
      content: "";
      background: $primary-color;
      width: em(50);
      height: em(5);
      position: absolute;
      left: 0;
      right: 0;
      bottom: em(-10);
      margin: 0 auto;
      border-radius: em(5) em(5) 0 0;
      opacity: 0;
    }

    &.active {
      color: $secondary-color !important;

      &:before {
        opacity: 1;
      }
    }
  }

}



@media (min-width: 1200px) {
  .nav-tabs {
    .nav-link {
      &:hover {
        color: $secondary-color !important;
      }
    }
  }
}