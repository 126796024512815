$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}



@mixin transitions($transitions...) {
    -webkit-transition: $transitions;
    -o-transition: $transitions;
    transition: $transitions;
  }