@import "../../style/variables";
@import "../../style/mixins";


// // To hide the Choose another way to pay toggle on braintree drop in UI.
// [data-braintree-id="toggle"] {
//   display: none;
// }


.braintree-method {
  .braintree-method__label {
    overflow: visible;
  }
}


.donation-amount-accordian {
  .braintree-toggle {
   //margin-bottom: em(15);
  }
  .braintree-option {
    border: 1px solid $primary-color;
    outline: none;
    margin-bottom: em(10);
  }

  .braintree-option__label {
    color: $primary-color;
    font-weight: 600;
  }

    .braintree-form__field

    {
        width: 100%;
    }


    .braintree-sheet__content--form
    {
        width: em(700);
    }

    .braintree-toggle
    {
        outline: none !important;
        @include transitions(0.5s);

        span
        {
            color:$primary-color;
            @include transitions(0.5s);
        }
    }

}