label {
  font-size: em(15);
  color: $gray-shade-2;
  margin: 0;
}

.field-group {
  margin-bottom: em(35);
}

.form-control {
  background: none !important;
  border: 0;
  border-bottom: 2px solid #dddddd;
  border-radius: 0;
  box-shadow: none !important;
  padding: em(10) 0;
  height: em(35);
  color: #375251;
  font-size: em(16);
  margin: 0 !important;
  position: relative;
  outline: none;

  &:focus {
    color: #375251;
    border-color: $secondary-color;
  }

  &.disabled {
    opacity: 0.6;
  }
}

.pass-field-box {
  position: relative;

  .eye-btn {
    width: em(25);
    height: em(25);
    background: url("../Assets/Images/eye-close.svg") no-repeat center;
    position: absolute;
    top: em(27);
    right: 0;
    cursor: pointer;

    &.open {
      background: url("../Assets/Images/eye-open.svg") no-repeat center;
    }
  }
}

.icon-right {
  width: em(25);
  height: em(25);
  position: absolute;
  top: em(27);
  right: 0;
  cursor: pointer;
}

.form-control-select {
  position: relative;
  margin: em(5) 0 0 0;

  .dropdown-toggle {
    background: $light-gray !important;
    border: 0;
    border-radius: em(10);
    width: 100%;
    height: em(56);
    padding: em(10) em(20);
    outline: none !important;
    box-shadow: none !important;
    position: relative;
    font-weight: 600;
    font-size: unset;

    &:after {
      position: absolute;
      right: em(18);
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 0;
      height: em(8);
      border-left: em(8) solid transparent;
      border-right: em(8) solid transparent;
      border-top: em(8) solid $dark-gray;
      border-radius: 5px;

      [dir="rtl"] & {
        left: em(18);
        right: auto;
      }
    }

    [dir="rtl"] & {
      text-align: right !important;
    }
  }

  .dropdown-menu {
    min-width: 100%;
    border: 2px solid $light-gray;
    background: $white-color !important;
    max-height: em(200);
    overflow: hidden;
    overflow-y: auto;
    padding: em(10) 0;
    border-radius: em(10);
  }

  .dropdown-item {
    background: none !important;
    color: #375251 !important;
  }

  &.disabled {
    opacity: 0.6;
  }
}

.form-control-multi-select {
  position: relative;
  margin: em(5) 0 0 0;

  label {
    display: block !important;
  }

  .actions-btn {
    background: none;
    padding: 0;
    border: 0 !important;
    font-weight: 600;
    margin: 0 0 em(5) em(18);
    font-size: em(16);
    box-shadow: none !important;
  }

  .dropdown-toggle {
    background: $light-gray !important;
    border: 0;
    border-radius: em(10);
    width: 100%;
    height: em(56);
    padding: em(10) em(20);
    outline: none !important;
    box-shadow: none !important;
    position: relative;
    font-weight: 600;
    font-size: unset;

    &:after {
      position: absolute;
      right: em(18);
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 0;
      height: em(8);
      border-left: em(8) solid transparent;
      border-right: em(8) solid transparent;
      border-top: em(8) solid $dark-gray;
      border-radius: 5px;

      [dir="rtl"] & {
        left: em(18);
        right: auto;
      }
    }

    span {
      [dir="rtl"] & {
        text-align: right !important;
      }
    }
  }

  .dropdown-menu {
    min-width: 100%;
    border: 2px solid $light-gray;
    background: $white-color !important;
    max-height: em(200);
    overflow: hidden;
    overflow-y: auto;
    padding: em(10) 0 !important;
    border-radius: em(10);
  }

  .dropdown-item {
    background: none !important;
    color: #375251 !important;
    padding: em(3) em(15);
  }

  &.disabled {
    opacity: 0.6;
  }

  .form-check {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: em(34);
    height: em(34);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;

    input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
      width: em(34);
      z-index: 1;
      font-size: em(16);
    }

    .form-check-label {
      cursor: pointer;
      padding-left: em(13);

      [dir="rtl"] & {
        padding-right: em(20);
      }

      &:before {
        content: "";
        position: absolute;
        top: em(5);
        left: 0;
        height: em(25);
        width: em(25);
        background: #e5e5e5;
        border-radius: 50%;
      }
    }

    input:checked ~ .form-check-label {
      &:before {
        background: $primary-color;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: stretch;
      }
    }

    input:checked ~ .form-check-label {
      &:after {
        display: block;
        content: "\ea10";
        color: $white-color;
      }
    }

    .form-check-label {
      &:after {
        font-family: "icomoon" !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: em(6);
        top: em(13);
        font-size: em(14);
      }
    }

    .field-group {
      margin-bottom: auto;
    }

    .invalid-feedback {
      position: absolute;
      left: 0;
      bottom: em(-25);
      width: em(250);
    }
  }
}

.form-control-date {
  position: relative;
  margin-top: em(5);
  background: $light-gray;
  height: em(55);
  padding: em(15) em(15);
  border-radius: em(10);

  &:before {
    content: "\e904";
    position: absolute;
    top: em(9);
    right: em(10);
    color: $secondary-color;
    font-size: em(25);
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    [dir="rtl"] & {
      left: em(10);
      right: auto;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  input {
    background: none;
    border: 0;
    outline: none;
    width: 100%;
    font-size: em(16);
    font-weight: 600;
    color: $secondary-color;
    position: relative;
    cursor: pointer;
    padding-right: em(30);

    [dir="rtl"] & {
      padding-right: 0;
      padding-left: em(30);
    }
  }

  .react-datepicker__day--selected {
    background: $primary-color !important;
    outline: none !important;
  }
}

.form-control-time {
  position: relative;
  margin-top: em(5);
  background: $light-gray;
  height: em(55);
  padding: 0;
  border-radius: em(10);
  display: flex;

  &:before {
    content: "\e911";
    position: absolute;
    top: em(9);
    right: em(10);
    color: $secondary-color;
    font-size: em(25);
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    [dir="rtl"] & {
      right: auto;
      right: em(10);
    }
  }

  .form-control-select {
    width: 15%;
    margin: 0 em(5);
  }

  .dropdown-toggle {
    padding-left: em(15);
    padding-right: em(10);
    text-align: center !important;

    &:after {
      opacity: 0;
    }
  }

  .dot {
    font-weight: 600 !important;
    font-size: em(20);
    height: 100%;
    padding: em(9) 0;
  }
}

.form-control-btn-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: em(20) em(-12) em(40) em(-12);

  .btn-parent-box {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 em(12);

    button {
      min-width: 100%;
      background: $light-gray !important;
      color: #a9a7a8 !important;

      &.btn-active {
        background: $primary-color !important;
        color: $white-color !important;
      }
    }
  }
}

.form-control-without-label {
  label {
    display: none;
  }
}

select::-ms-expand {
  display: none;
}

.form-control-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: em(34);
  height: em(34);

  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: em(34);
    z-index: 1;
    font-size: em(16);
  }

  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 100%;
    width: 100%;
    background: #e5e5e5;
    border-radius: 50%;

    &:after {
      position: absolute;
      display: none;
    }
  }

  input:checked ~ .checkmark {
    background: $primary-color;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
  }

  input:checked ~ .checkmark:after {
    display: block;
    content: "\ea10";
    color: $white-color;
  }

  .checkmark:after {
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
  }

  .field-group {
    margin-bottom: auto;
  }

  .invalid-feedback {
    position: absolute;
    left: 0;
    bottom: em(-25);
    width: em(250);
  }
}

.form-control-radio-group {
  display: flex;
  flex-wrap: wrap;
  margin-right: em(-15);
  margin-left: em(-15);
  margin-bottom: em(40);
}

.form-control-radio {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: $light-gray;
  padding: em(15) em(20);
  margin: 0 em(15);
  border-radius: em(10);
  height: em(55);
  flex: 0 0 30%;
  max-width: 30%;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    right: em(20);
    bottom: 0;
    margin: auto 0;
    height: em(18);
    width: em(18);
    background-color: $white-color;
    border-radius: 50%;

    [dir="rtl"] & {
      right: auto;
      left: em(20);
    }
  }

  label {
    font-size: em(17);
    color: $secondary-color;
    font-weight: 700;
  }

  input:checked ~ .checkmark {
    background-color: $white-color;
  }

  input:checked ~ .checkmark:after {
    background: $primary-color;
  }

  .checkmark:after {
    content: "";
    width: 60%;
    height: 60%;
    border-radius: 50%;
    background: #bcc1c1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.is-valid {
  border-color: $success-color !important;
  //background: url("../Assets/Images/check.svg") no-repeat  !important;
  //background-position:em(300) em(3)  !important;
}

.is-invalid {
  border-color: $error-color !important;
  line-height: em(18);
}

button {
  outline: none !important;
}

.generic-note-msg {
  margin-top: em(15);
  font-style: italic;
}

.c-switch {
  width: em(55);
  height: em(30);
}

.c-switch-slider {
  background: $gray-shade-2 !important;
  box-shadow: none !important;
  border: 0;

  &:before {
    border: 0 !important;
    width: em(20);
    height: em(20);
    top: 0;
    bottom: 0;
    left: em(6);
    margin: auto 0;
  }
}

.c-switch-primary .c-switch-input:checked + .c-switch-slider {
  background: $primary-color !important;
}

.c-switch-input:checked ~ .c-switch-slider::before {
  transform: translateX(18px);
}

@media (min-width: 1200px) {
  .form-control-select {
    .dropdown-item {
      &:hover {
        background: #eaeaea !important;
      }
    }
  }
  .form-control-btn-group {
    .btn-parent-box {
      button {
        &:hover {
          background: $primary-color !important;
          color: $white-color !important;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .form-control-multi-select {
    .dropdown-menu {
      font-size: 12px;
    }
  }
}
