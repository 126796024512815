@font-face {
  font-family: 'product_sansregular';
  src: url('../Assets/fonts/product_sans_400-webfont.woff2') format('woff2'),
  url('../Assets/fonts/product_sans_400-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'product_sansbold';
  src: url('../Assets/fonts/product_sans_bold_700-webfont.woff2') format('woff2'),
  url('../Assets/fonts/product_sans_bold_700-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'icomoon';
  src:  url('../Assets/fonts/icomoon.eot?wa2qsg');
  src:  url('../Assets/fonts/icomoon.eot?wa2qsg#iefix') format('embedded-opentype'),
  url('../Assets/fonts/icomoon.ttf?wa2qsg') format('truetype'),
  url('../Assets/fonts/icomoon.woff?wa2qsg') format('woff'),
  url('../Assets/fonts/icomoon.svg?wa2qsg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'KFGQPC Uthman Taha Naskh';
  src: url('../Assets/fonts/KFGQPCUthmanTahaNaskh.woff2') format('woff2'),
  url('../Assets/fonts/KFGQPCUthmanTahaNaskh.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}




@font-face {
  font-family: 'Noto-Nastaliq-Urdu';
  src: url('../Assets/fonts/Noto-Nastaliq-Urdu.ttf.woff') format('woff'),
  url('../Assets/fonts/Noto-Nastaliq-Urdu.ttf.svg#Noto-Nastaliq-Urdu') format('svg'),
  url('../Assets/fonts/Noto-Nastaliq-Urdu.ttf.eot'),
  url('../Assets/fonts/Noto-Nastaliq-Urdu.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../Assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
    url('../Assets/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-donate:before {
  content: "\e918";
}
.icon-shape:before {
  content: "\e911";
}
.icon-create-khitmah:before {
  content: "\e905";
}
.icon-profile:before {
  content: "\e910";
}
.icon-calendar:before {
  content: "\e904";
}
.icon-down-arrow:before {
  content: "\e903";
}
.icon-left-arrow:before {
  content: "\e90e";
}
.icon-check:before {
  content: "\e900";
}
.icon-about-khitmah:before {
  content: "\e901";
}
.icon-donation:before {
  content: "\e906";
}
.icon-home:before {
  content: "\e907";
}
.icon-join-khitma:before {
  content: "\e908";
}
.icon-logout:before {
  content: "\e909";
}
.icon-mykhitma:before {
  content: "\e90a";
}
.icon-payment-info:before {
  content: "\e90b";
}
.icon-pledges:before {
  content: "\e90c";
}
.icon-tutorial:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e90f";
}
.icon-map-pin:before {
  content: "\e915";
}
.icon-edit:before {
  content: "\e914";
}
.icon-share-2:before {
  content: "\e912";
}
.icon-close-solid:before {
  content: "\e917";
}
.icon-help-with-circle:before {
  content: "\e916";
}
.icon-envelope:before {
  content: "\e913";
}
.icon-clock:before {
  content: "\e94e";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-google-plus:before {
  content: "\ea8b";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-bell-o:before {
  content: "\e902";
}